.image{
     height: 80px;
     width: 300px;
     text-align: center;
     margin-top: 20px;
}
.center{
   text-align: center;
    margin-top: 15%;
}
